import { MapPin } from 'lucide-react';

interface LocalTipsProps {
  tips: string[];
}

export default function LocalTips({ tips }: LocalTipsProps) {
  return (
    <div className="space-y-3 sm:space-y-4">
      <h3 className="text-lg sm:text-xl font-medium text-white">Local Tips</h3>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-4">
        {tips.map((tip, index) => (
          <div
            key={index}
            className="flex items-start gap-3 p-3 bg-white/5 rounded-lg"
          >
            <span className="h-2 w-2 rounded-full bg-yellow-400 mt-2 flex-shrink-0" />
            <p className="text-gray-300">{tip}</p>
          </div>
        ))}
      </div>
    </div>
  );
}