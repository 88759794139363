import { useState } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { Plus, Clock, MapPin, Activity, Utensils, Hotel, Trash2, Calendar } from 'lucide-react';
import { format, addDays, parseISO } from 'date-fns';
import DateNavigation from './DateNavigation';
import ScheduleHeader from './ScheduleHeader';
import ScheduleColumn from './ScheduleColumn';
import AddScheduleItemModal from '../Itineraries/AddScheduleItemModal';
import type { Day, ScheduleItem } from '../../types/schedule';
import DateCard from './DateCard';

interface ScheduleViewProps {
  startDate: string | null;
  endDate: string | null;
  numberOfDays: number | null;
  days: Day[];
  onUpdateSchedule: (days: Day[]) => void;
  onSetDates: () => void;
}

export default function ScheduleView({
  startDate,
  endDate,
  numberOfDays,
  days,
  onUpdateSchedule,
  onSetDates
}: ScheduleViewProps) {
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [showAddModal, setShowAddModal] = useState(false);

  // Generate array of dates or day numbers
  const scheduleDates = (() => {
    if (startDate && endDate) {
      // Use actual dates
      const dates: string[] = [];
      let currentDate = new Date(startDate);
      const end = new Date(endDate);
      
      while (currentDate <= end) {
        dates.push(currentDate.toISOString().split('T')[0]);
        currentDate = addDays(currentDate, 1);
      }
      return dates;
    } else if (numberOfDays && numberOfDays > 0) {
      // Use numbered days
      return Array.from(
        { length: numberOfDays },
        (_, i) => `day-${i + 1}`
      );
    }
    return [];
  })();

  // Set initial selected date if not set
  if (!selectedDate && scheduleDates.length > 0) {
    setSelectedDate(scheduleDates[0]);
  }

  const getScheduleForDate = (date: string): ScheduleItem[] => {
    if (!date) return [];
    const day = days.find(d => d.date === date);
    return day?.schedule || [];
  };

  // If no dates or numberOfDays set, show prompt
  if (!startDate && !endDate && !numberOfDays) {
    return (
      <div className="flex flex-col items-center justify-center h-[300px] bg-white/5 rounded-lg border border-white/10">
        <Calendar className="h-12 w-12 text-accent-400 mb-4" />
        <h3 className="text-lg font-medium text-white mb-2">No Dates Set</h3>
        <p className="text-gray-400 text-center max-w-sm mb-4">
          Set your trip dates to start planning your daily schedule
        </p>
        <button 
          onClick={onSetDates}
          className="px-4 py-2 bg-accent-500 hover:bg-accent-600 text-white rounded-lg transition-colors"
        >
          Set Trip Dates
        </button>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="schedule-container overflow-x-auto">
        <div className="flex gap-4 pb-4">
          {scheduleDates.map((date) => (
            <DateCard
              key={date}
              date={date}
              isSelected={date === selectedDate}
              hasItems={getScheduleForDate(date).length > 0}
              onClick={() => setSelectedDate(date)}
              itemCount={getScheduleForDate(date).length}
              getScheduleItems={getScheduleForDate}
            />
          ))}
        </div>
      </div>

      {/* Rest of the component remains the same */}
    </div>
  );
}