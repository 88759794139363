import { Utensils, ExternalLink, MapPin, MousePointerClick, BookmarkPlus, Check, ChevronDown, Plus, X } from 'lucide-react';
import { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useTripSlip } from '../contexts/TripSlipContext';
import type { DiningResult } from '../types/dining';
import StarRating from './ui/StarRating';
import Alert from './ui/Alert';
import axios from 'axios';

interface DiningResultsProps {
  results: DiningResult[];
  isLoading: boolean;
  error?: string | null;
  destinationName?: string;
  currentItineraryId?: string;
}

export default function DiningResults({
  results,
  isLoading,
  error,
  destinationName,
  currentItineraryId
}: DiningResultsProps) {
  const { isAuthenticated, user } = useAuth();
  const { addDiningToDestination } = useTripSlip();
  const [savingId, setSavingId] = useState<string | null>(null);
  const [savedIds, setSavedIds] = useState<string[]>([]);
  const [showAlert, setShowAlert] = useState(false);
  const [localResults, setLocalResults] = useState(results);

  useEffect(() => {
    setLocalResults(results);
  }, [results]);

  const handleSave = async (dining: DiningResult) => {
    if (!isAuthenticated || !user?.googleId) return;

    setSavingId(dining.name);
    try {
      if (currentItineraryId) {
        // Save to itinerary
        await axios.post(`/api/users/itineraries/${currentItineraryId}/dining`, {
          dining,
          googleId: user.googleId
        });
      } else if (destinationName) {
        // Save to TripSlip
        await addDiningToDestination(destinationName, dining);
      }

      setSavedIds(prev => [...prev, dining.name]);
      setShowAlert(true);
    } catch (error) {
      console.error('Error saving dining option:', error);
    } finally {
      setSavingId(null);
    }
  };

  const handleRemove = (restaurantName: string) => {
    setLocalResults(prev => prev.filter(r => r.name !== restaurantName));
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center py-8">
        <div className="flex flex-col items-center gap-2">
          <Utensils className="h-6 w-6 text-accent-400 animate-bounce" />
          <p className="text-sm text-gray-300">Searching for dining options...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center py-4">
        <p className="text-sm text-red-400">{error}</p>
      </div>
    );
  }

  if (!results?.length) return null;

  return (
    <div className="relative space-y-6">
      {showAlert && (
        <Alert
          type="success"
          message="Restaurant saved successfully!"
          onClose={() => setShowAlert(false)}
        />
      )}

      {/* Scroll Indicator */}
      <div className="absolute -top-4 left-1/2 -translate-x-1/2 flex items-center gap-2 text-accent-400 bg-gray-900/90 px-3 py-1.5 rounded-full border border-accent-500/20 backdrop-blur-sm z-10">
        <MousePointerClick className="h-4 w-4 animate-bounce" />
        <span className="text-sm">Scroll to see more</span>
      </div>

      {/* Results Container */}
      <div className="relative max-h-[70vh] overflow-y-auto pr-4 -mr-4 space-y-6 dining-results-container">
        {/* Gradient Fade Top */}
        <div className="sticky top-0 left-0 right-4 h-6 bg-gradient-to-b from-gray-900 to-transparent z-[1]" />

        {localResults.map((restaurant, index) => (
          <div
            key={`${restaurant.name}-${index}`}
            className="bg-gray-900/60 backdrop-blur-sm rounded-xl overflow-hidden border border-white/10 hover:border-accent-500/30 transition-all duration-300 group relative"
          >
            {/* Add delete button with more visible styling */}
            <button
              onClick={() => handleRemove(restaurant.name)}
              className="absolute top-3 right-3 z-10 p-2 bg-gray-900/90 text-gray-300 
                         hover:text-red-400 hover:bg-red-500/30 rounded-lg transition-all 
                         duration-200 border border-white/10 hover:border-red-500/30 
                         shadow-lg backdrop-blur-sm group/delete"
              title="Remove from results"
            >
              <X className="h-5 w-5 group-hover/delete:scale-110 transition-transform" />
            </button>

            {/* Images */}
            <div className="relative h-48 overflow-hidden">
              <div className="flex h-full">
                {restaurant.images.slice(0, 3).map((image, imgIndex) => (
                  <div
                    key={imgIndex}
                    className="flex-1 relative first:rounded-tl-xl last:rounded-tr-xl overflow-hidden"
                  >
                    <img
                      src={image}
                      alt={`${restaurant.name} - Image ${imgIndex + 1}`}
                      className="absolute inset-0 w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
                    />
                  </div>
                ))}
              </div>
              <div className="absolute inset-0 bg-gradient-to-t from-gray-900/60 to-transparent opacity-0 group-hover:opacity-100 transition-opacity" />
            </div>

            {/* Content */}
            <div className="p-6 space-y-4">
              <div className="flex items-start justify-between gap-4">
                <div>
                  <h3 className="text-xl font-semibold text-white mb-1 group-hover:text-accent-400 transition-colors">
                    {restaurant.name}
                  </h3>
                  <div className="flex items-center gap-4">
                    <p className="text-accent-400">{restaurant.cuisine}</p>
                    <StarRating value={restaurant.rating} readonly size="sm" onChange={() => {}} />
                  </div>
                </div>
                <div className="flex flex-col items-end gap-2">
                  <span className="text-lg font-bold text-white">
                    {restaurant.priceRange}
                  </span>
                  {restaurant.reservationRequired && (
                    <p className="text-xs text-accent-400">
                      Reservations Required
                    </p>
                  )}
                  <button
                    onClick={() => handleSave(restaurant)}
                    disabled={!isAuthenticated || savingId === restaurant.name || savedIds.includes(restaurant.name)}
                    className={`
                      p-2 rounded-lg transition-all duration-200
                      ${!isAuthenticated
                        ? 'bg-gray-800/50 text-gray-600 cursor-not-allowed'
                        : savedIds.includes(restaurant.name)
                        ? 'bg-emerald-500/20 text-emerald-400'
                        : 'bg-accent-500/20 text-accent-400 hover:bg-accent-500/30 hover:text-accent-300'
                      }
                    `}
                    title={
                      !isAuthenticated
                        ? 'Sign in to save restaurants'
                        : savedIds.includes(restaurant.name)
                        ? 'Restaurant saved'
                        : 'Save restaurant'
                    }
                  >
                    {savedIds.includes(restaurant.name) ? (
                      <Check className="h-5 w-5" />
                    ) : (
                      <BookmarkPlus className={`h-5 w-5 ${savingId === restaurant.name ? 'animate-pulse' : ''}`} />
                    )}
                  </button>
                </div>
              </div>

              <p className="text-gray-300">{restaurant.description}</p>

              <div className="flex items-center gap-2 text-gray-400">
                <MapPin className="h-4 w-4 flex-shrink-0" />
                <span className="text-sm">{restaurant.location}</span>
              </div>

              {/* Specialties */}
              <div className="space-y-2">
                <h4 className="text-sm font-medium text-white">Signature Dishes:</h4>
                <div className="flex flex-wrap gap-2">
                  {restaurant.specialties.map((specialty, index) => (
                    <span
                      key={index}
                      className="px-3 py-1 bg-accent-500/10 text-accent-400 rounded-full text-sm hover:bg-accent-500/20 transition-colors"
                    >
                      {specialty}
                    </span>
                  ))}
                </div>
              </div>

              {/* Atmosphere */}
              <div className="bg-white/5 rounded-lg p-3 hover:bg-white/10 transition-colors">
                <p className="text-sm text-gray-300">{restaurant.atmosphere}</p>
              </div>

              {/* Links */}
              <div className="grid gap-2">
                {restaurant.links.map((link, index) => (
                  <a
                    key={index}
                    href={link.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center justify-between p-3 bg-white/5 hover:bg-white/10 rounded-lg transition-colors group"
                  >
                    <span className="text-gray-300 group-hover:text-white">
                      {link.title}
                    </span>
                    <ExternalLink className="h-4 w-4 text-gray-500 group-hover:text-accent-400" />
                  </a>
                ))}
              </div>

              {/* Save to Trip Button - Only show in itinerary context */}
              {currentItineraryId && (
                <button
                  onClick={() => handleSave(restaurant)}
                  disabled={!isAuthenticated || savingId === restaurant.name || savedIds.includes(restaurant.name)}
                  className={`
                    w-full flex items-center justify-center gap-2 p-3 rounded-lg transition-colors
                    ${!isAuthenticated
                      ? 'bg-gray-800/50 text-gray-600 cursor-not-allowed'
                      : savedIds.includes(restaurant.name)
                      ? 'bg-emerald-500/20 text-emerald-400'
                      : 'bg-orange-500/20 text-orange-400 hover:bg-orange-500/30'
                    }
                  `}
                >
                  {savedIds.includes(restaurant.name) ? (
                    <>
                      <Check className="h-5 w-5" />
                      Added to Trip
                    </>
                  ) : (
                    <>
                      <Plus className="h-5 w-5" />
                      Add to Trip
                    </>
                  )}
                </button>
              )}
            </div>
          </div>
        ))}

        {/* Gradient Fade Bottom */}
        <div className="sticky bottom-0 left-0 right-4 h-6 bg-gradient-to-t from-gray-900 to-transparent z-[1]" />
      </div>
    </div>
  );
}