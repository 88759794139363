import { DollarSign, Calendar, Hotel, Clock, Activity, MapPin, Edit2, Check, X } from 'lucide-react';
import { useState } from 'react';
import { format } from 'date-fns';
import type { DestinationDetails } from '../../types/itinerary';
import LocalTips from './LocalTips';
import ItineraryActivities from './ItineraryActivities';
import ItineraryDining from './ItineraryDining';

interface ItineraryDetailsProps {
  destination: DestinationDetails;
  itineraryId: string;
  onActivitySaved: () => void;
  onDiningSaved: () => void;
  onBudgetUpdate?: (amount: string) => Promise<void>;
  numberOfDays?: number | null;
  daysCount: number;
}

const formatDate = (dateString: string) => {
  try {
    return format(new Date(dateString), 'MMMM d, yyyy');
  } catch (error) {
    console.error('Error formatting date:', error);
    return dateString;
  }
};

export default function ItineraryDetails({ 
  destination, 
  itineraryId, 
  onActivitySaved,
  onDiningSaved,
  onBudgetUpdate,
  numberOfDays,
  daysCount
}: ItineraryDetailsProps) {
  const [isEditing, setIsEditing] = useState(false);
  const [spentAmount, setSpentAmount] = useState(destination.actualSpent || '');
  const [isLoading, setIsLoading] = useState(false);

  // Calculate display days based on the specific itinerary or fall back to recommended
  const displayDays = numberOfDays ?? daysCount;
  const daysDisplay = displayDays || destination.recommendedDuration || 'Not set';
  const isRecommended = !numberOfDays && !daysCount && destination.recommendedDuration;

  const handleSave = async () => {
    if (!onBudgetUpdate) return;
    setIsLoading(true);
    try {
      await onBudgetUpdate(spentAmount);
      setIsEditing(false);
    } catch (error) {
      console.error('Failed to update budget:', error);
    }
    setIsLoading(false);
  };

  return (
    <div className="space-y-4 sm:space-y-8">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-3 sm:gap-4">
        {/* Budget Section */}
        <div className="h-full">
          <div className="flex items-center gap-2 text-accent-400 mb-3 sm:mb-4">
            <DollarSign className="h-5 w-5" />
            <span className="font-medium">Budget Tracker</span>
          </div>
          <div className="h-[calc(100%-2rem)]">
            <div className="bg-white/5 rounded-lg p-4 sm:p-6 h-full">
              <div className="space-y-4">
                <div>
                  <div className="text-sm text-gray-400">Estimated Budget</div>
                  <div className="text-lg sm:text-xl font-bold text-accent-400">{destination.estimatedBudget}</div>
                </div>
                
                <div>
                  <div className="flex justify-between items-center">
                    <div className="text-sm text-gray-400">Amount Spent</div>
                    {isEditing ? (
                      <div className="flex items-center gap-1 sm:gap-2">
                        <input
                          type="text"
                          value={spentAmount}
                          onChange={(e) => setSpentAmount(e.target.value)}
                          className="w-24 sm:w-28 bg-white/10 rounded px-2 py-1 text-accent-400 text-sm"
                          placeholder="Enter amount"
                        />
                        <button 
                          onClick={handleSave}
                          disabled={isLoading}
                          className="text-green-400 hover:text-green-300"
                        >
                          <Check className="h-4 w-4" />
                        </button>
                        <button 
                          onClick={() => setIsEditing(false)}
                          className="text-red-400 hover:text-red-300"
                        >
                          <X className="h-4 w-4" />
                        </button>
                      </div>
                    ) : (
                      <div className="flex items-center gap-2">
                        <span className="text-lg sm:text-xl font-bold text-accent-400">
                          {spentAmount || '$0'}
                        </span>
                        <button 
                          onClick={() => setIsEditing(true)}
                          className="text-gray-400 hover:text-accent-400"
                        >
                          <Edit2 className="h-4 w-4" />
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="h-1.5 bg-white/10 rounded-full overflow-hidden mt-2">
                    <div 
                      className="h-full bg-accent-400/50 rounded-full transition-all duration-300"
                      style={{ 
                        width: `${spentAmount ? (parseFloat(spentAmount.replace(/[^0-9.-]+/g, '')) / parseFloat(destination.estimatedBudget.replace(/[^0-9.-]+/g, '')) * 100) : 0}%`
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Accommodation Section */}
        <div className="h-full">
          <div className="flex items-center gap-2 text-accent-400 mb-3 sm:mb-4">
            <Hotel className="h-5 w-5" />
            <span className="font-medium">Accommodation</span>
          </div>
          <div className="h-[calc(100%-2rem)]">
            <div className="bg-white/5 rounded-lg p-4 sm:p-6 h-full">
              <div className="space-y-4">
                <div>
                  <div className="text-sm text-gray-400">Recommended Stay</div>
                  <div className="text-lg sm:text-xl font-bold text-accent-400">{destination.accommodation}</div>
                </div>
                
                <div className="space-y-3">
                  <div className="flex items-center gap-2">
                    <Clock className="h-4 w-4 text-gray-400" />
                    <span className="text-gray-300 text-sm sm:text-base">
                      {daysDisplay} {typeof daysDisplay === 'number' ? 'days' : ''}
                      {isRecommended && (
                        <span className="text-xs sm:text-sm text-accent-400 ml-1">(recommended)</span>
                      )}
                    </span>
                  </div>
                  <div className="flex items-center gap-2">
                    <MapPin className="h-4 w-4 text-gray-400" />
                    <span className="text-gray-300 text-sm sm:text-base">{destination.location}</span>
                  </div>
                  {destination.amenities && (
                    <div className="pt-2 border-t border-white/10">
                      <div className="text-sm text-gray-400 mb-2">Amenities</div>
                      <div className="flex flex-wrap gap-1.5 sm:gap-2">
                        {destination.amenities.map((amenity, index) => (
                          <span 
                            key={index}
                            className="text-xs px-2 py-0.5 sm:py-1 rounded-full bg-white/5 text-gray-300"
                          >
                            {amenity}
                          </span>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Activities and Dining Sections */}
      <div className="space-y-4 sm:space-y-8">
        {/* Activities Section */}
        <div>
          <div className="bg-white/5 rounded-lg sm:rounded-xl p-3 sm:p-4">
            <ItineraryActivities 
              destination={destination.name} 
              itineraryId={itineraryId}
              onActivitySaved={onActivitySaved}
            />
          </div>
        </div>

        {/* Dining Section */}
        <div>
          <div className="bg-white/5 rounded-lg sm:rounded-xl p-3 sm:p-4">
            <ItineraryDining
              destination={destination.name}
              itineraryId={itineraryId}
              onDiningSaved={onDiningSaved}
            />
          </div>
        </div>

        {/* Local Tips Section */}
        <div>
          <div className="bg-white/5 rounded-lg sm:rounded-xl p-3 sm:p-4">
            <LocalTips tips={destination.localTips} />
          </div>
        </div>

        {/* Created Date */}
        <div className="text-xs sm:text-sm text-gray-400 px-1">
          Created on {formatDate(destination.createdAt)}
        </div>
      </div>
    </div>
  );
}