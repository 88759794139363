import OpenAI from 'openai';

const openai = new OpenAI({
  apiKey: import.meta.env.VITE_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true,
});

interface ActivityResult {
  title: string;
  description: string;
  links: Array<{
    title: string;
    url: string;
  }>;
  price?: string;
  duration?: string;
}

export async function searchActivityDetails(
  activity: string,
  destination: string,
  count: number = 15,
  existingTitles: string[] = []
): Promise<ActivityResult[]> {
  const prompt = `Find ${count} unique activities related to "${activity}" in ${destination}.
${existingTitles.length > 0 ? `Please exclude these existing activities:
${existingTitles.map(title => `- ${title}`).join('\n')}` : ''}

For each option, provide EXACTLY ${count} activities with:
1. A descriptive title that captures the unique aspect of this variation
2. A compelling description (2-3 sentences) highlighting what makes this option special
3. At least 3 relevant links for booking or information:
   - For general activities, include links from:
     - TripAdvisor (format: https://www.tripadvisor.com/Search?q=${activity}+${destination})
     - Viator (format: https://www.viator.com/search/${destination}?q=${activity})
     - GetYourGuide (format: https://www.getyourguide.com/s?q=${activity}+${destination})
   - For sports events, concerts, or shows, also include links from:
     - Ticketmaster (format: https://www.ticketmaster.com/search?q=${activity}+${destination})
     - StubHub (format: https://www.stubhub.com/search?q=${activity}+${destination})
   - Replace spaces with + in the search terms
4. Approximate price range with currency (e.g. "$50-100 USD", "€75-150")
5. Typical duration including any seasonal variations

You must return EXACTLY ${count} activities in the results array.

Return the response as valid JSON with this structure:
{
  "results": [
    {
      "title": "string",
      "description": "string", 
      "links": [
        {"title": "string", "url": "string"}
      ],
      "price": "string",
      "duration": "string"
    }
  ]
}

Ensure the response is a valid JSON object with exactly ${count} results.`;

  try {
    const completion = await openai.chat.completions.create({
      messages: [{ 
        role: 'user', 
        content: prompt,
      }],
      model: 'gpt-4',
      temperature: 1.0,
    });

    const response = completion.choices[0].message.content;
    if (!response) throw new Error('No response from AI');

    try {
      const data = JSON.parse(response);
      return data.results;
    } catch (parseError) {
      console.error('Error parsing AI response:', parseError);
      throw new Error('Failed to parse activity details');
    }
  } catch (error) {
    console.error('Error searching activity details:', error);
    throw new Error('Failed to search activity details');
  }
}

export async function getMoreActivitySuggestions(
  activity: string,
  destination: string,
  existingTitles: string[],
  additionalCount: number = 5
): Promise<ActivityResult[]> {
  return searchActivityDetails(activity, destination, additionalCount, existingTitles);
}
