import { BlogClient } from 'seobot';
import type { IArticle } from '../types/blog';

const client = new BlogClient(import.meta.env.VITE_SEOBOT_API_KEY);

export async function getArticles(page = 0, limit = 10): Promise<{ articles: IArticle[], total: number }> {
  try {
    console.log(await client.getArticles(page, limit));
    const response = await client.getArticles(page, limit);
    return {
      articles: response.articles || [],
      total: response.total || 0
    };
  } catch (error) {
    console.error('Error fetching articles:', error);
    throw new Error('Failed to fetch articles');
  }
}

export async function getCategoryArticles(categorySlug: string, page = 0, limit = 10): Promise<{ articles: IArticle[], total: number }> {
  try {
    //console.log(await client.getCategoryArticles(categorySlug, page, limit));
    const response = await client.getCategoryArticles(categorySlug, page, limit);
    return {
      articles: response.articles || [],
      total: response.total || 0
    };
  } catch (error) {
    console.error('Error fetching category articles:', error);
    throw new Error('Failed to fetch category articles');
  }
}

export async function getArticle(slug: string): Promise<IArticle> {
  try {
    const response = await client.getArticle(slug);
    if (!response) {
      throw new Error('Article not found');
    }
    return response;
  } catch (error) {
    console.error('Error fetching article:', error);
    throw new Error('Failed to fetch article');
  }
}