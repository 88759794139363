import OpenAI from 'openai';
import axios from 'axios';
import type { DiningResult } from '../types/dining';

const openai = new OpenAI({
  apiKey: import.meta.env.VITE_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true,
});

export async function searchDining(
  query: string,
  location: string,
  starRating?: number
): Promise<DiningResult[]> {
  const prompt = `Find detailed information about "${query}" dining options in ${location}${
    starRating ? ` with a rating of ${starRating} stars or higher` : ''
  }.

Provide at least 10 restaurant recommendations. For each restaurant, include:
1. Name and cuisine type
2. A compelling description (2-3 sentences)
3. Price range (using $ symbols, $-$$$$)
4. Rating (1-5 stars)
5. Three high-quality images from Pexels that showcase the restaurant or similar cuisine:
   - Use format: https://images.pexels.com/photos/{photo-id}/pexels-photo-{photo-id}.jpeg
   - Choose images that represent the cuisine and atmosphere
6. Exact or approximate location
7. 3-4 signature dishes or specialties
8. Description of the atmosphere
9. Whether reservations are typically required
10. Links for booking or information (use only TripAdvisor and OpenTable):
    - TripAdvisor: https://www.tripadvisor.com/Search?q={restaurant}+{location}
    - OpenTable: https://www.opentable.com/s?term={restaurant}+{location}
    (Replace spaces with + in search terms)

If searching for a specific restaurant, include similar alternatives in the area.

Format the response as valid JSON with this structure:
{
  "results": [
    {
      "name": "string",
      "cuisine": "string",
      "description": "string",
      "priceRange": "string",
      "rating": number,
      "images": ["url1", "url2", "url3"],
      "location": "string",
      "specialties": ["string"],
      "atmosphere": "string",
      "reservationRequired": boolean,
      "links": [
        {"title": "string", "url": "string"}
      ]
    }
  ]
}`;

  try {
    const completion = await openai.chat.completions.create({
      messages: [{ role: 'user', content: prompt }],
      model: 'gpt-4-turbo-preview',
      temperature: 0.7,
      response_format: { type: "json_object" },
    });

    const response = completion.choices[0].message.content;
    if (!response) throw new Error('No response from AI');

    const data = JSON.parse(response);
    return data.results;
  } catch (error) {
    console.error('Error searching dining options:', error);
    throw new Error('Failed to search dining options');
  }
}

export async function saveDiningToDestination(
  dining: DiningResult,
  destinationName: string,
  googleId: string
): Promise<void> {
  try {
    await axios.post(
      '/api/users/dining/save',
      { dining, destinationName },
      { headers: { Authorization: `Bearer ${googleId}` } }
    );
  } catch (error) {
    console.error('Error saving dining option:', error);
    throw error;
  }
}

export async function removeDiningFromDestination(
  diningId: string,
  googleId: string
): Promise<void> {
  try {
    await axios.delete(`/api/users/dining/${diningId}`, {
      headers: { Authorization: `Bearer ${googleId}` }
    });
  } catch (error) {
    console.error('Error removing dining option:', error);
    throw error;
  }
}

export async function getDiningForDestination(
  destinationName: string,
  googleId: string
): Promise<DiningResult[]> {
  try {
    const response = await axios.get(`/api/users/dining/${destinationName}`, {
      headers: { Authorization: `Bearer ${googleId}` }
    });
    return response.data;
  } catch (error) {
    console.error('Error getting dining options:', error);
    throw error;
  }
}