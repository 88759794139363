import { useState } from 'react';
import { Minus, Plus } from 'lucide-react';
import { format, differenceInDays, parseISO } from 'date-fns';
import type { Itinerary } from '../../types/itinerary';

interface DateSettingModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (startDate: Date | null, endDate: Date | null, numberOfDays?: number) => void;
  currentItinerary: Itinerary | undefined;
}

export default function DateSettingModal({ isOpen, onClose, onSave, currentItinerary }: DateSettingModalProps) {
  const hasExistingDates = !!(currentItinerary?.destination?.startDate && currentItinerary?.destination?.endDate);
  const [useDaysOnly, setUseDaysOnly] = useState(!hasExistingDates && !currentItinerary?.startDate);
  const [startDate, setStartDate] = useState<string>(
    currentItinerary?.destination?.startDate 
      ? format(new Date(currentItinerary.destination.startDate), 'yyyy-MM-dd')
      : ''
  );
  const [endDate, setEndDate] = useState<string>(
    currentItinerary?.destination?.endDate 
      ? format(new Date(currentItinerary.destination.endDate), 'yyyy-MM-dd')
      : ''
  );
  const [numberOfDays, setNumberOfDays] = useState<number>(
    currentItinerary?.destination?.numberOfDays || 1
  );

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (useDaysOnly) {
      onSave(null, null, numberOfDays);
    } else if (startDate && endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      // Calculate days including both start and end date
      const calculatedDays = differenceInDays(end, start) + 1;
      
      // Pass both dates and the calculated number of days
      onSave(start, end, calculatedDays);
    }
  };

  const incrementDays = () => setNumberOfDays(prev => prev + 1);
  const decrementDays = () => setNumberOfDays(prev => Math.max(1, prev - 1));

  // Update numberOfDays when dates change
  const handleDateChange = (type: 'start' | 'end', value: string) => {
    if (type === 'start') {
      setStartDate(value);
    } else {
      setEndDate(value);
    }

    // If both dates are valid, update the number of days
    if (startDate && value) {
      const start = new Date(type === 'start' ? value : startDate);
      const end = new Date(type === 'end' ? value : endDate);
      if (start && end && !isNaN(start.getTime()) && !isNaN(end.getTime())) {
        const days = differenceInDays(end, start) + 1;
        if (days > 0) {
          setNumberOfDays(days);
        }
      }
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50">
      <div className="bg-gray-900 rounded-xl p-6 w-full max-w-md border border-white/10">
        <h2 className="text-xl font-bold text-white mb-6">Set Trip Dates</h2>
        
        <div className="mb-6">
          <label className={`relative flex items-center justify-between p-4 rounded-lg bg-gray-800/50 border border-gray-700/50 
            ${hasExistingDates 
              ? 'opacity-50 cursor-not-allowed' 
              : 'hover:border-accent-500/50 transition-colors cursor-pointer group'
            }`}
          >
            <span className={`text-gray-300 ${!hasExistingDates && 'group-hover:text-white transition-colors'}`}>
              I don't know the exact dates yet
            </span>
            <input
              type="checkbox"
              checked={useDaysOnly}
              onChange={(e) => setUseDaysOnly(e.target.checked)}
              disabled={hasExistingDates}
              className="peer sr-only"
            />
            <div className="relative w-11 h-6 bg-gray-700 peer-checked:bg-accent-500 rounded-full transition-colors">
              <div className="absolute left-1 top-1 w-4 h-4 bg-white rounded-full transition-transform peer-checked:translate-x-5" />
            </div>
          </label>
          {hasExistingDates && (
            <p className="mt-2 text-sm text-amber-400">
              To set number of days only, please clear the existing dates first.
            </p>
          )}
        </div>

        <form onSubmit={handleSubmit} className="space-y-4">
          {!useDaysOnly ? (
            <>
              <div>
                <label htmlFor="startDate" className="block text-sm font-medium text-gray-300 mb-1">
                  Start Date
                </label>
                <input
                  type="date"
                  id="startDate"
                  value={startDate}
                  onChange={(e) => handleDateChange('start', e.target.value)}
                  className="w-full bg-gray-800 border border-gray-700 rounded-lg px-3 py-2 text-white focus:border-accent-500/50 focus:ring-1 focus:ring-accent-500/50 transition-colors"
                  required
                />
              </div>
              <div>
                <label htmlFor="endDate" className="block text-sm font-medium text-gray-300 mb-1">
                  End Date
                </label>
                <input
                  type="date"
                  id="endDate"
                  value={endDate}
                  onChange={(e) => handleDateChange('end', e.target.value)}
                  className="w-full bg-gray-800 border border-gray-700 rounded-lg px-3 py-2 text-white focus:border-accent-500/50 focus:ring-1 focus:ring-accent-500/50 transition-colors"
                  required
                  min={startDate}
                />
              </div>
            </>
          ) : (
            <div className="flex items-center justify-center gap-4">
              <button
                type="button"
                onClick={decrementDays}
                className="p-2 rounded-lg bg-gray-800 text-gray-300 hover:text-white hover:bg-gray-700 transition-colors"
              >
                <Minus className="h-4 w-4" />
              </button>
              <div className="flex flex-col items-center">
                <span className="text-2xl font-bold text-white">{numberOfDays}</span>
                <span className="text-sm text-gray-400">days</span>
              </div>
              <button
                type="button"
                onClick={incrementDays}
                className="p-2 rounded-lg bg-gray-800 text-gray-300 hover:text-white hover:bg-gray-700 transition-colors"
              >
                <Plus className="h-4 w-4" />
              </button>
            </div>
          )}
          
          <div className="flex justify-end gap-3 mt-6">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-300 hover:text-white transition-colors"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 text-sm font-medium bg-accent-500 text-white rounded-lg hover:bg-accent-600 transition-colors"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
} 