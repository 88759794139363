import { useState, useEffect } from 'react';
import { X, Calendar, MapPin, Search, Plus, Minus } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';
import axios from 'axios';

interface SavedDestination {
  _id: string;
  name: string;
  description: string;
  bestTimeToVisit: string;
  recommendedDuration: string;
  activities: string[];
  estimatedBudget: string;
  accommodation: string;
  localTips: string[];
  tripDescription: string;
  surveyResponses: any;
  savedActivitySuggestions: any[];
  savedDining: any[];
}

interface CreateItineraryModalProps {
  onClose: () => void;
  onCreate: (data: any) => void;
}

export default function CreateItineraryModal({ onClose, onCreate }: CreateItineraryModalProps) {
  const { user } = useAuth();
  const [savedDestinations, setSavedDestinations] = useState<SavedDestination[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedDestination, setSelectedDestination] = useState<SavedDestination | null>(null);
  const [useDaysOnly, setUseDaysOnly] = useState(true);
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    startDate: '',
    endDate: '',
    numberOfDays: 1
  });

  useEffect(() => {
    loadSavedDestinations();
  }, [user?.googleId]);

  const loadSavedDestinations = async () => {
    try {
      const response = await axios.get('/api/users/destinations', {
        headers: { Authorization: `Bearer ${user?.googleId}` }
      });
      setSavedDestinations(response.data);
    } catch (error) {
      console.error('Error loading saved destinations:', error);
    }
  };

  const filteredDestinations = savedDestinations.filter(dest => 
    dest.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleDestinationSelect = (destination: SavedDestination) => {
    setSelectedDestination(destination);
    setFormData(prev => ({
      ...prev,
      title: `Trip to ${destination.name}`,
      description: destination.description
    }));
  };

  const incrementDays = () => {
    setFormData(prev => ({ ...prev, numberOfDays: prev.numberOfDays + 1 }));
  };

  const decrementDays = () => {
    setFormData(prev => ({ ...prev, numberOfDays: Math.max(1, prev.numberOfDays - 1 )}));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!selectedDestination) return;

    const itineraryData = {
      title: formData.title,
      description: formData.description,
      destination: {
        _id: selectedDestination._id,
        name: selectedDestination.name,
        description: selectedDestination.description,
        activities: selectedDestination.activities,
        estimatedBudget: selectedDestination.estimatedBudget,
        bestTimeToVisit: selectedDestination.bestTimeToVisit,
        accommodation: selectedDestination.accommodation,
        recommendedDuration: selectedDestination.recommendedDuration,
        localTips: selectedDestination.localTips,
        tripDescription: selectedDestination.tripDescription,
        surveyResponses: selectedDestination.surveyResponses,
        savedActivitySuggestions: selectedDestination.savedActivitySuggestions,
        savedDining: selectedDestination.savedDining,
        startDate: useDaysOnly ? null : formData.startDate,
        endDate: useDaysOnly ? null : formData.endDate,
        days: [],
        numberOfDays: useDaysOnly ? formData.numberOfDays : 
          Math.ceil((new Date(formData.endDate).getTime() - new Date(formData.startDate).getTime()) / (1000 * 60 * 60 * 24)) + 1
      }
    };

    onCreate(itineraryData);
    onClose();
  };

  return (
    <div className="fixed inset-0 z-50">
      <div className="fixed inset-0 bg-black/80 backdrop-blur-sm" onClick={onClose} />
      
      <div className="fixed inset-0 pointer-events-none flex items-center justify-center p-4">
        <div className="relative w-full max-w-lg bg-gray-900 rounded-2xl shadow-xl pointer-events-auto">
          <div className="flex items-center justify-between p-6 border-b border-gray-800">
            <div className="flex items-center gap-3">
              <Calendar className="h-5 w-5 text-accent-400" />
              <h3 className="text-lg font-semibold text-white">Create New Itinerary</h3>
            </div>
            <button
              onClick={onClose}
              className="p-2 text-gray-400 hover:text-white rounded-lg transition-colors"
            >
              <X className="h-5 w-5" />
            </button>
          </div>

          <div className="p-6 space-y-6">
            {!selectedDestination ? (
              <div className="space-y-4">
                <div className="relative">
                  <Search className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-gray-400" />
                  <input
                    type="text"
                    placeholder="Search your saved destinations..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="w-full bg-white/5 border border-white/10 rounded-lg pl-10 pr-4 py-2.5 text-white focus:ring-2 focus:ring-accent-400 focus:border-transparent"
                  />
                </div>
                
                <div className="space-y-2 max-h-[300px] overflow-y-auto">
                  {filteredDestinations.map((destination) => (
                    <button
                      key={destination._id}
                      onClick={() => handleDestinationSelect(destination)}
                      className="w-full p-4 bg-white/5 hover:bg-white/10 rounded-lg transition-colors text-left group"
                    >
                      <div className="flex items-start gap-3">
                        <MapPin className="h-5 w-5 text-accent-400 flex-shrink-0 mt-0.5" />
                        <div>
                          <h4 className="font-medium text-white mb-1">{destination.name}</h4>
                          <p className="text-sm text-gray-400 line-clamp-2">{destination.description}</p>
                        </div>
                      </div>
                    </button>
                  ))}
                </div>
              </div>
            ) : (
              <form onSubmit={handleSubmit} className="space-y-6">
                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-300">Title</label>
                  <input
                    type="text"
                    value={formData.title}
                    onChange={(e) => setFormData(prev => ({ ...prev, title: e.target.value }))}
                    className="w-full bg-white/5 border border-white/10 rounded-lg px-4 py-2.5 text-white focus:ring-2 focus:ring-accent-400 focus:border-transparent"
                    required
                  />
                </div>

                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-300">Description</label>
                  <textarea
                    value={formData.description}
                    onChange={(e) => setFormData(prev => ({ ...prev, description: e.target.value }))}
                    className="w-full bg-white/5 border border-white/10 rounded-lg px-4 py-2.5 text-white focus:ring-2 focus:ring-accent-400 focus:border-transparent"
                    rows={3}
                  />
                </div>

                <div className="mb-6">
                  <label className="relative flex items-center justify-between p-4 rounded-lg bg-white/5 border border-white/10 hover:border-accent-500/50 transition-colors cursor-pointer group">
                    <span className="text-gray-300 group-hover:text-white transition-colors">
                      I don't know the exact dates yet
                    </span>
                    <input
                      type="checkbox"
                      checked={useDaysOnly}
                      onChange={(e) => setUseDaysOnly(e.target.checked)}
                      className="peer sr-only"
                    />
                    <div className="relative w-11 h-6 bg-gray-700 peer-checked:bg-accent-500 rounded-full transition-colors">
                      <div className="absolute left-1 top-1 w-4 h-4 bg-white rounded-full transition-transform peer-checked:translate-x-5" />
                    </div>
                  </label>
                </div>

                {!useDaysOnly ? (
                  <div className="grid grid-cols-2 gap-4">
                    <div className="space-y-2">
                      <label className="block text-sm font-medium text-gray-300">Start Date</label>
                      <input
                        type="date"
                        value={formData.startDate}
                        onChange={(e) => setFormData(prev => ({ ...prev, startDate: e.target.value }))}
                        className="w-full bg-white/5 border border-white/10 rounded-lg px-4 py-2.5 text-white focus:ring-2 focus:ring-accent-400 focus:border-transparent"
                        required
                      />
                    </div>
                    <div className="space-y-2">
                      <label className="block text-sm font-medium text-gray-300">End Date</label>
                      <input
                        type="date"
                        value={formData.endDate}
                        onChange={(e) => setFormData(prev => ({ ...prev, endDate: e.target.value }))}
                        className="w-full bg-white/5 border border-white/10 rounded-lg px-4 py-2.5 text-white focus:ring-2 focus:ring-accent-400 focus:border-transparent"
                        required
                        min={formData.startDate}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="flex items-center justify-center gap-4">
                    <button
                      type="button"
                      onClick={decrementDays}
                      className="p-2 rounded-lg bg-white/5 text-gray-300 hover:text-white hover:bg-white/10 transition-colors"
                    >
                      <Minus className="h-4 w-4" />
                    </button>
                    <div className="flex flex-col items-center">
                      <span className="text-2xl font-bold text-white">{formData.numberOfDays}</span>
                      <span className="text-sm text-gray-400">days</span>
                    </div>
                    <button
                      type="button"
                      onClick={incrementDays}
                      className="p-2 rounded-lg bg-white/5 text-gray-300 hover:text-white hover:bg-white/10 transition-colors"
                    >
                      <Plus className="h-4 w-4" />
                    </button>
                  </div>
                )}

                <div className="flex justify-end gap-4">
                  <button
                    type="button"
                    onClick={() => setSelectedDestination(null)}
                    className="px-4 py-2 text-gray-300 hover:text-white hover:bg-white/10 rounded-lg transition-colors"
                  >
                    Back
                  </button>
                  <button
                    type="submit"
                    className="px-4 py-2 bg-accent-500 hover:bg-accent-600 text-white rounded-lg transition-colors"
                  >
                    Create Itinerary
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}