import { useState } from 'react';
import { Activity, Search, Plus, X } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';
import useSWR from 'swr';
import axios from 'axios';
import ActivitySearch from '../TripSlip/ActivitySearch';
import SavedActivities from '../TripSlip/SavedActivities';
import { createPortal } from 'react-dom';

interface SavedActivity {
  _id: string;
  title: string;
  description: string;
  price?: string;
  duration?: string;
  location?: string;
  links: Array<{
    title: string;
    url: string;
    _id: string;
  }>;
  savedAt: string;
}

interface ItineraryActivitiesProps {
  destination: string;
  itineraryId: string;
}

const fetcher = async (url: string, token: string) => {
  const response = await axios.get(url, {
    headers: { Authorization: `Bearer ${token}` }
  });
  return response.data?.destination?.savedActivitySuggestions || [];
};

export default function ItineraryActivities({ destination, itineraryId }: ItineraryActivitiesProps) {
  const { user } = useAuth();
  const [showSearch, setShowSearch] = useState(false);
  const [showSaved, setShowSaved] = useState(false);

  // Use SWR for real-time activity updates
  const { data: savedActivities, mutate } = useSWR(
    user?.googleId ? [`/api/users/itineraries/${itineraryId}`, user.googleId] : null,
    ([url, token]) => fetcher(url, token),
    {
      refreshInterval: 1000, // Poll every second
    }
  );

  const handleDeleteActivity = async (activityId: string) => {
    try {
      await axios.delete(`/api/users/itineraries/${itineraryId}/activities/${activityId}`, {
        headers: { Authorization: `Bearer ${user?.googleId}` }
      });
      // Trigger revalidation after deletion
      mutate();
    } catch (error) {
      console.error('Error deleting activity:', error);
    }
  };

  return (
    <div className="space-y-4">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2 text-accent-400">
          <Activity className="h-5 w-5" />
          <span className="font-medium">Activities</span>
        </div>
        <button
          onClick={() => setShowSearch(true)}
          className="px-4 py-2 bg-accent-500/20 hover:bg-accent-500/30 text-accent-400 rounded-lg transition-colors"
        >
          Search Activities
        </button>
      </div>

      {showSearch && createPortal(
        <div className="fixed inset-0 z-[100] flex items-center justify-center bg-black/50">
          <div className="relative w-full max-w-2xl mx-4">
            <ActivitySearch
              activity=""
              destination={destination}
              onClose={() => setShowSearch(false)}
              itineraryId={itineraryId}
              onActivitySaved={() => mutate()}
            />
          </div>
        </div>,
        document.body
      )}

      {showSaved && (
        <SavedActivities
          activities={savedActivities || []}
          onClose={() => setShowSaved(false)}
          onDelete={handleDeleteActivity}
        />
      )}
    </div>
  );
} 