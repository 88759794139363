import { useState, useEffect } from 'react';
import { Utensils, X, Plus } from 'lucide-react';
import { searchDining } from '../../services/dining';
import type { DiningResult } from '../../types/dining';
import DiningResults from '../DiningResults';
import StarRating from '../ui/StarRating';
import LoadingSpinner from '../ui/LoadingSpinner';

interface DiningSearchProps {
  destination: string;
  onClose: () => void;
  itineraryId: string;
  onDiningSaved: () => void;
}

export default function DiningSearch({
  destination,
  onClose,
  itineraryId,
  onDiningSaved
}: DiningSearchProps) {
  const [searchQuery, setSearchQuery] = useState('');
  const [starRating, setStarRating] = useState(0);
  const [searchResults, setSearchResults] = useState<DiningResult[] | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchDining = async () => {
    setIsLoading(true);
    setError(null);
    try {
      // Initial load with 15 restaurants
      const results = await searchDining(searchQuery || 'restaurant', destination, starRating || undefined);
      setSearchResults(results);
      localStorage.setItem(`dining-search-${destination}`, JSON.stringify(results));
    } catch (err) {
      console.error('Error fetching dining options:', err);
      setError('Failed to load dining options. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleLoadMore = async () => {
    setIsLoadingMore(true);
    setError(null);
    try {
      const existingTitles = searchResults?.map(result => result.name) || [];
      
      // Load more with additional restaurants
      const additionalResults = await searchDining(
        searchQuery || 'restaurant',
        destination,
        starRating || undefined
      );
      
      setSearchResults(prev => prev ? [...prev, ...additionalResults] : additionalResults);
      localStorage.setItem(`dining-search-${destination}`, 
        JSON.stringify(searchResults ? [...searchResults, ...additionalResults] : additionalResults)
      );
    } catch (err) {
      console.error('Error fetching more dining options:', err);
      setError('Failed to load more options. Please try again.');
    } finally {
      setIsLoadingMore(false);
    }
  };

  // Load cached results on mount
  useEffect(() => {
    const cachedResults = localStorage.getItem(`dining-search-${destination}`);
    if (cachedResults) {
      try {
        setSearchResults(JSON.parse(cachedResults));
      } catch (e) {
        console.error('Error parsing cached results:', e);
      }
    }
  }, [destination]);

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center p-4">
      <div className="bg-gray-900/95 backdrop-blur-sm rounded-xl border border-white/10 w-full max-w-2xl max-h-[90vh] flex flex-col">
        <div className="p-6 flex-shrink-0">
          {/* Header */}
          <div className="flex justify-between items-center">
            <h3 className="text-xl font-semibold text-white">Find Restaurants</h3>
            <button onClick={onClose} className="text-gray-400 hover:text-white">
              <X className="h-5 w-5" />
            </button>
          </div>

          {/* Search Controls */}
          <div className="space-y-4 mt-4">
            <div className="relative">
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                onKeyPress={(e) => e.key === 'Enter' && fetchDining()}
                placeholder="Search for cuisine or restaurant..."
                className="w-full pl-12 pr-4 py-3 bg-white/10 border border-white/20 rounded-xl text-white placeholder-gray-400 focus:ring-2 focus:ring-accent-400 focus:outline-none"
              />
              <Utensils className="absolute left-4 top-3.5 h-5 w-5 text-gray-400" />
            </div>

            <div className="flex items-center gap-3">
              <StarRating value={starRating} onChange={setStarRating} size="md" />
              {starRating > 0 && (
                <button
                  onClick={() => setStarRating(0)}
                  className="text-sm text-accent-400 hover:text-accent-300"
                >
                  Clear Rating
                </button>
              )}
            </div>

            <button
              onClick={fetchDining}
              disabled={isLoading}
              className={`w-full py-3 px-4 rounded-lg font-medium transition-all duration-200
                ${!isLoading
                  ? 'bg-accent-500 hover:bg-accent-600 text-white'
                  : 'bg-gray-700 text-gray-400 cursor-not-allowed'}`}
            >
              {isLoading ? 'Searching...' : 'Search'}
            </button>
          </div>
        </div>

        {/* Results */}
        <div className="flex-1 overflow-y-auto px-6 pb-6">
          {(isLoading || searchResults || error) && (
            <div>
              <DiningResults
                results={searchResults || []}
                isLoading={isLoading}
                error={error}
                destination={destination}
                itineraryId={itineraryId}
                onDiningSaved={onDiningSaved}
              />

              {/* Load More Button */}
              {searchResults && searchResults.length > 0 && (
                <button
                  onClick={handleLoadMore}
                  disabled={isLoadingMore}
                  className="w-full mt-4 py-3 px-4 bg-white/5 hover:bg-white/10 rounded-lg transition-colors flex items-center justify-center gap-2 text-gray-300 hover:text-white disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {isLoadingMore ? (
                    <LoadingSpinner className="h-4 w-4" />
                  ) : (
                    <Plus className="h-4 w-4" />
                  )}
                  <span>
                    {isLoadingMore ? 'Loading more options...' : 'Load more options'}
                  </span>
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}