import { useState } from 'react';
import { Utensils } from 'lucide-react';
import DiningSearch from '../DiningSearch';
import { createPortal } from 'react-dom';

interface ItineraryDiningProps {
  destination: string;
  itineraryId: string;
  onDiningSaved: () => void;
}

export default function ItineraryDining({ destination, itineraryId, onDiningSaved }: ItineraryDiningProps) {
  const [showDiningSearch, setShowDiningSearch] = useState(false);

  return (
    <div className="space-y-3 sm:space-y-4">
      <h3 className="text-lg sm:text-xl font-medium text-white">Dining</h3>
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2 text-accent-400">
          <Utensils className="h-5 w-5" />
          <span className="font-medium">Dining Options</span>
        </div>
        <button
          onClick={() => setShowDiningSearch(true)}
          className="px-4 py-2 bg-accent-500/20 hover:bg-accent-500/30 text-accent-400 rounded-lg transition-colors"
        >
          Find Restaurants
        </button>
      </div>

      {showDiningSearch && createPortal(
        <div className="fixed inset-0 z-[100] flex items-center justify-center bg-black/50">
          <div className="relative w-full max-w-2xl mx-4">
            <DiningSearch
              destination={destination}
              onClose={() => setShowDiningSearch(false)}
              currentItineraryId={itineraryId}
              onDiningSaved={onDiningSaved}
            />
          </div>
        </div>,
        document.body
      )}
    </div>
  );
} 