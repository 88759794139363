import { useState, useEffect } from 'react';
import { X, RotateCcw, Trash2, Check, Plus } from 'lucide-react';
import { searchActivityDetails, getMoreActivitySuggestions } from '../../services/activities';
import ActivityCard from './ActivitySearchModal/ActivityCard';
import LoadingSpinner from '../ui/LoadingSpinner';
import { useAuth } from '../../contexts/AuthContext';
import axios from 'axios';

interface ActivitySearchProps {
  activity: string;
  destination: string;
  onClose: () => void;
  itineraryId: string;
  onActivitySaved: () => void;
}

interface SavedState {
  [key: string]: boolean;
}

export default function ActivitySearch({ activity, destination, onClose, itineraryId, onActivitySaved }: ActivitySearchProps) {
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [savedStates, setSavedStates] = useState<SavedState>(() => {
    // Try to load saved states from localStorage
    const cached = localStorage.getItem(`saved-states-${destination}`);
    return cached ? JSON.parse(cached) : {};
  });
  const [showAlert, setShowAlert] = useState(false);
  const { user } = useAuth();
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  // Load cached results and saved states if they exist
  useEffect(() => {
    const cachedResults = localStorage.getItem(`activity-search-${destination}`);
    if (cachedResults) {
      setSearchResults(JSON.parse(cachedResults));
      setIsLoading(false);
    } else {
      fetchActivities();
    }
  }, [destination]);

  // Save states to localStorage whenever they change
  useEffect(() => {
    localStorage.setItem(`saved-states-${destination}`, JSON.stringify(savedStates));
  }, [savedStates, destination]);

  const fetchActivities = async () => {
    setIsLoading(true);
    setError(null);
    try {
      // Initial load with 15 activities
      const results = await searchActivityDetails(activity || 'tourist', destination, 3);
      setSearchResults(results);
      localStorage.setItem(`activity-search-${destination}`, JSON.stringify(results));
    } catch (err) {
      console.error('Error fetching activities:', err);
      setError('Failed to load activities. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const clearResults = () => {
    localStorage.removeItem(`activity-search-${destination}`);
    localStorage.removeItem(`saved-states-${destination}`);
    setSavedStates({});
    setSearchResults([]);
    fetchActivities();
  };

  const handleSaveActivity = async (activityData: any) => {
    if (!itineraryId) {
      console.error('No itinerary ID provided');
      return;
    }

    try {
      const response = await axios.post(`http://localhost:3001/api/users/itineraries/${itineraryId}/activities`, {
        activity: {
          title: activityData.title,
          description: activityData.description,
          price: activityData.price,
          duration: activityData.duration,
          links: activityData.links,
          savedAt: new Date()
        }
      }, {
        headers: {
          'Authorization': `Bearer ${user?.googleId}`
        }
      });

      if (response.status === 200) {
        // Update saved states
        setSavedStates(prev => ({
          ...prev,
          [activityData.title]: true
        }));
        setShowAlert(true);
        onActivitySaved();
        
        // Only hide the alert after 3 seconds
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
      }
    } catch (error) {
      console.error('Error saving activity:', error);
      setError('Failed to save activity. Please try again.');
    }
  };

  const handleLoadMore = async () => {
    setIsLoadingMore(true);
    setError(null);
    try {
      const existingTitles = searchResults.map(result => result.title);
      
      // Load more with exactly 5 additional activities
      const additionalResults = await getMoreActivitySuggestions(
        activity || 'tourist',
        destination,
        existingTitles,
        5  // Explicitly pass 5 for additional results
      );
      
      setSearchResults(prev => [...prev, ...additionalResults]);
      localStorage.setItem(`activity-search-${destination}`, 
        JSON.stringify([...searchResults, ...additionalResults])
      );
    } catch (err) {
      console.error('Error fetching more activities:', err);
      setError('Failed to load more activities. Please try again.');
    } finally {
      setIsLoadingMore(false);
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center p-4">
      <div className="bg-gray-900/95 backdrop-blur-sm rounded-xl border border-white/10 w-full max-w-2xl flex flex-col">
        <div className="flex justify-between items-center p-6 border-b border-white/10">
          <h3 className="text-lg font-medium text-white">
            Activities in {destination}
          </h3>
          <div className="flex items-center gap-2">
            <button
              onClick={clearResults}
              className="p-1 text-gray-400 hover:text-white transition-colors group"
              title="Clear results"
            >
              <Trash2 className="h-5 w-5" />
              <span className="sr-only">Clear results</span>
            </button>
            <button
              onClick={fetchActivities}
              className="p-1 text-gray-400 hover:text-white transition-colors"
              title="Refresh results"
            >
              <RotateCcw className="h-5 w-5" />
            </button>
            <button
              onClick={onClose}
              className="p-1 text-gray-400 hover:text-white transition-colors"
            >
              <X className="h-5 w-5" />
            </button>
          </div>
        </div>

        {showAlert && (
          <div className="mx-6 mt-4 p-4 bg-green-500/10 border border-green-500/20 rounded-lg flex items-center gap-2 text-green-400">
            <Check className="h-5 w-5" />
            <span>Activity saved successfully!</span>
          </div>
        )}

        <div className="flex-1">
          {isLoading ? (
            <div className="flex justify-center py-8">
              <LoadingSpinner />
            </div>
          ) : error ? (
            <div className="text-red-400 text-center py-4">{error}</div>
          ) : (
            <div className="h-[calc(3*168px)] overflow-y-auto p-6 space-y-4 custom-scrollbar">
              {searchResults.map((result, index) => (
                <div
                  key={index}
                  className={`transition-all duration-300 ${
                    savedStates[result.title] ? 'ring-2 ring-green-500/50' : ''
                  }`}
                >
                  <ActivityCard
                    activity={result}
                    onSave={handleSaveActivity}
                    isSaved={savedStates[result.title]}
                  />
                </div>
              ))}
              
              {/* Load More Button */}
              <button
                onClick={handleLoadMore}
                disabled={isLoadingMore}
                className="w-full py-3 px-4 bg-white/5 hover:bg-white/10 rounded-lg transition-colors flex items-center justify-center gap-2 text-gray-300 hover:text-white disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {isLoadingMore ? (
                  <LoadingSpinner className="h-4 w-4" />
                ) : (
                  <Plus className="h-4 w-4" />
                )}
                <span>{isLoadingMore ? 'Loading more activities...' : 'Load more activities'}</span>
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}