import { ExternalLink, ChevronDown, ChevronUp, Check } from 'lucide-react';
import { useState } from 'react';
import type { ActivityResult } from '../../../types/activities';
import { useAuth } from '../../../contexts/AuthContext';
import axios from 'axios';
import Alert from '../../ui/Alert';

interface ActivityCardProps {
  activity: {
    title: string;
    description: string;
    links: Array<{
      title: string;
      url: string;
    }>;
    price?: string;
    duration?: string;
  };
  onSave?: (activity: any) => void;
  isSaved?: boolean;
}

export default function ActivityCard({ activity, onSave, isSaved }: ActivityCardProps) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isSavedActivity, setIsSavedActivity] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const { isAuthenticated, user } = useAuth();

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const handleSaveActivity = async () => {
    if (!isAuthenticated || !user?.googleId) return;

    setIsSaving(true);
    try {
      // If we have a destinationName and it's not "nearby", save to that destination
      if (destinationName && destinationName !== 'nearby') {
        await axios.post(
          '/api/users/activities/save',
          {
            destinationName,
            originalActivity,
            activity: {
              title: activity.title,
              description: activity.description,
              price: activity.price,
              duration: activity.duration,
              images: activity.images,
              links: activity.links
            }
          },
          {
            headers: { Authorization: `Bearer ${user.googleId}` }
          }
        );
      } else {
        // Otherwise, save as a general search activity
        await axios.post(
          '/api/users/activities/search/save',
          {
            activity: {
              title: activity.title,
              description: activity.description,
              price: activity.price,
              duration: activity.duration,
              images: activity.images,
              links: activity.links
            }
          },
          {
            headers: { Authorization: `Bearer ${user.googleId}` }
          }
        );
      }
      setIsSavedActivity(true);
      setShowAlert(true);
    } catch (error) {
      console.error('Error saving activity:', error);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="bg-white/5 rounded-lg p-4 space-y-4">
      <div className="flex justify-between items-start gap-4">
        <div className="space-y-2 flex-1">
          <h3 className="text-lg font-medium text-white">{activity.title}</h3>
          <p className={`text-gray-300 text-sm ${!isExpanded && 'line-clamp-2'}`}>
            {activity.description}
          </p>
        </div>
        <button
          onClick={toggleExpand}
          className="p-1 hover:bg-white/10 rounded-lg transition-colors"
        >
          {isExpanded ? (
            <ChevronUp className="h-5 w-5 text-gray-400" />
          ) : (
            <ChevronDown className="h-5 w-5 text-gray-400" />
          )}
        </button>
      </div>

      {isExpanded && (
        <div className="space-y-4">
          {(activity.price || activity.duration) && (
            <div className="flex gap-4 text-sm">
              {activity.price && (
                <div>
                  <span className="text-gray-400">Price:</span>{' '}
                  <span className="text-white">{activity.price}</span>
                </div>
              )}
              {activity.duration && (
                <div>
                  <span className="text-gray-400">Duration:</span>{' '}
                  <span className="text-white">{activity.duration}</span>
                </div>
              )}
            </div>
          )}

          <div className="space-y-2">
            <h4 className="text-sm font-medium text-gray-400">Booking Links</h4>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
              {activity.links.map((link, index) => (
                <a
                  key={index}
                  href={link.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center gap-2 text-sm text-accent-400 hover:text-accent-300 transition-colors"
                >
                  <ExternalLink className="h-4 w-4" />
                  <span>{link.title}</span>
                </a>
              ))}
            </div>
          </div>

          {onSave && (
            <button
              onClick={() => !isSaved && onSave(activity)}
              className={`w-full py-2 px-4 rounded-lg text-sm font-medium transition-all duration-300 flex items-center justify-center gap-2
                ${
                  isSaved
                    ? 'bg-green-500/20 text-green-300 cursor-default transform hover:scale-100'
                    : 'bg-accent-500 hover:bg-accent-600 text-white hover:scale-[1.02]'
                }`}
              disabled={isSaved}
            >
              {isSaved ? (
                <>
                  <Check className="h-4 w-4" />
                  <span>Saved to Itinerary</span>
                </>
              ) : (
                'Save Activity'
              )}
            </button>
          )}
        </div>
      )}

      {showAlert && (
        <Alert
          type="success"
          message="Activity saved successfully!"
          onClose={() => setShowAlert(false)}
        />
      )}
    </div>
  );
}