import { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Calendar, LogIn, Plus } from 'lucide-react';
import SEOHead from '@/components/SEO/SEOHead';
import StructuredData from '@/components/SEO/StructuredData';
import GoogleSignIn from '@/components/auth/GoogleSignIn';
import ItineraryList from '@/components/Itineraries/ItineraryList';
import CreateItineraryModal from '@/components/Itineraries/CreateItineraryModal';
import Alert from '@/components/ui/Alert';
import axios from 'axios';

export default function Itineraries() {
  const { isAuthenticated, user } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [itineraries, setItineraries] = useState([]);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    if (isAuthenticated && user?.googleId) {
      loadItineraries();
    } else {
      setIsLoading(false);
    }
  }, [isAuthenticated, user?.googleId]);

  const loadItineraries = async () => {
    try {
      const response = await axios.get('/api/users/itineraries', {
        headers: { Authorization: `Bearer ${user?.googleId}` }
      });
      setItineraries(response.data);
    } catch (error) {
      console.error('Error loading itineraries:', error);
      setAlertMessage('Failed to load itineraries. Please try again.');
      setShowAlert(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreateItinerary = async (data: any) => {
    try {
      const response = await axios.post('/api/users/itineraries', data, {
        headers: { Authorization: `Bearer ${user?.googleId}` }
      });
      setItineraries(prev => [...prev, response.data]);
      setShowCreateModal(false);
      setAlertMessage('Itinerary created successfully!');
      setShowAlert(true);
    } catch (error) {
      console.error('Error creating itinerary:', error);
      setAlertMessage('Failed to create itinerary. Please try again.');
      setShowAlert(true);
    }
  };

  const handleDeleteItinerary = async (id: string) => {
    try {
      // Optimistically remove from UI first
      setItineraries(current => current.filter(itinerary => itinerary._id !== id));
      
      // Then make the server call
      await axios.delete(`/api/itineraries/${id}`);
      
      // If server call fails, we'll fetch the fresh data
      // If it succeeds, UI is already updated
    } catch (err: any) {
      setError(err.response?.data?.message || 'Failed to delete itinerary');
      // Refresh the list to ensure UI is in sync with server
      loadItineraries();
    }
  };

  const pageData = {
    '@type': 'ItemList',
    itemListElement: itineraries.map((itinerary: any, index) => ({
      '@type': 'TravelAction',
      position: index + 1,
      name: itinerary.title,
      description: itinerary.description,
      startTime: itinerary.startDate,
      endTime: itinerary.endDate
    }))
  };

  if (!isAuthenticated) {
    return (
      <div className="min-h-screen bg-gradient-to-b from-gray-900 to-gray-800 py-24 select-none">
        <div className="max-w-lg mx-auto px-4">
          <div className="text-center space-y-6">
            <div className="bg-accent-500/10 rounded-full p-4 w-16 h-16 mx-auto">
              <Calendar className="w-8 h-8 text-accent-400" />
            </div>
            <h1 className="text-3xl font-bold text-white">Plan Your Perfect Trip</h1>
            <p className="text-gray-300">
              Sign in to create detailed itineraries, schedule activities, and organize your travel plans.
            </p>
            <div className="flex justify-center">
              <GoogleSignIn />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <SEOHead
        title="My Itineraries"
        description="View and manage your travel itineraries. Create detailed schedules and organize your trips."
        canonicalUrl="https://wanderwise.com/itineraries"
      />
      <StructuredData type="ItemList" data={pageData} />

      {showAlert && (
        <Alert
          type={alertMessage.includes('success') ? 'success' : 'error'}
          message={alertMessage}
          onClose={() => setShowAlert(false)}
        />
      )}

      <div className="min-h-screen bg-gradient-to-b from-gray-900 to-gray-800 py-12 md:py-24 select-none">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between mb-8">
            <div>
              <h1 className="text-2xl md:text-3xl font-bold text-white mb-2">My Itineraries</h1>
              <p className="text-gray-300">Create and manage your detailed travel schedules</p>
            </div>
            <button
              onClick={() => setShowCreateModal(true)}
              className="flex items-center gap-2 px-4 py-2 bg-accent-500 hover:bg-accent-600 text-white rounded-lg transition-colors"
            >
              <Plus className="h-5 w-5" />
              New Itinerary
            </button>
          </div>

          {isLoading ? (
            <div className="animate-pulse space-y-8">
              {[1, 2, 3].map((n) => (
                <div key={n} className="h-48 bg-white/5 rounded-2xl" />
              ))}
            </div>
          ) : itineraries.length === 0 ? (
            <div className="text-center py-12">
              <div className="bg-white/5 rounded-xl p-8 max-w-lg mx-auto">
                <Calendar className="w-12 h-12 text-gray-400 mx-auto mb-4" />
                <h3 className="text-xl font-medium text-white mb-2">No itineraries yet</h3>
                <p className="text-gray-400 mb-6">
                  Start planning your trips by creating detailed itineraries with activities and schedules.
                </p>
                <button
                  onClick={() => setShowCreateModal(true)}
                  className="inline-flex items-center gap-2 px-4 py-2 bg-accent-500 text-white rounded-lg hover:bg-accent-600 transition-colors"
                >
                  <Plus className="h-4 w-4" />
                  Create Itinerary
                </button>
              </div>
            </div>
          ) : (
            <ItineraryList
              itineraries={itineraries}
              onRefresh={loadItineraries}
              onDelete={handleDeleteItinerary}
            />
          )}
        </div>
      </div>

      {showCreateModal && (
        <CreateItineraryModal
          onClose={() => setShowCreateModal(false)}
          onCreate={handleCreateItinerary}
        />
      )}
    </>
  );
}