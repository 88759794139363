import { useState } from 'react';
import { format, isValid } from 'date-fns';
import useSWR from 'swr';
import {
  DndContext,
  DragOverlay,
  useSensor,
  useSensors,
  PointerSensor,
  KeyboardSensor,
  TouchSensor,
  MeasuringStrategy,
  defaultDropAnimationSideEffects
} from '@dnd-kit/core';
import axios from 'axios';
import { useAuth } from '../../contexts/AuthContext';
import Alert from '../ui/Alert';
import { ScheduleView } from '../Schedule';
import DraggableItem from './DraggableItem';
import DragOverlayItem from './DragOverlay';
import ItineraryHeader from './ItineraryHeader';
import ItineraryDetails from './ItineraryDetails';
import LocalTips from './LocalTips';
import { ChevronDown, ChevronUp, Plus } from 'lucide-react';
import type { Itinerary } from '../../types/itinerary';
import type { DraggableItemData, DroppableData } from '../../types/dnd';
import DiningSearch from '../DiningSearch';
import { sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { restrictToWindowEdges } from '@dnd-kit/modifiers';
import DateSettingModal from './DateSettingModal';

interface ItineraryListProps {
  itineraries: Itinerary[];
  onRefresh: () => void;
  isLoading: boolean;
}

const fetcher = async (url: string, token: string) => {
  const response = await axios.get(url, {
    headers: { Authorization: `Bearer ${token}` }
  });
  return response.data;
};

export default function ItineraryList({ itineraries: initialItineraries, onRefresh, isLoading }: ItineraryListProps) {
  const { user } = useAuth();
  const [expandedId, setExpandedId] = useState<string | null>(null);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [deletingId, setDeletingId] = useState<string | null>(null);
  const [activeId, setActiveId] = useState<string | null>(null);
  const [activeDragData, setActiveDragData] = useState<DraggableItemData | null>(null);
  const [showActivities, setShowActivities] = useState(true);
  const [showDining, setShowDining] = useState(true);
  const [showDateModal, setShowDateModal] = useState(false);
  const [selectedItineraryId, setSelectedItineraryId] = useState<string | null>(null);

  const touchBackendOptions = {
    enableMouseEvents: true,
    delayTouchStart: 50,
    touchSlop: 10,
    enableHoverOutsideTarget: true,
    ignoreContextMenu: true,
    scrollAngleRanges: [
      { start: 45, end: 135 },
      { start: 225, end: 315 }
    ]
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        delay: 50,
        tolerance: 3,
        distance: 4,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 50,
        tolerance: 3,
        distance: 4,
      },
      ...touchBackendOptions,
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  // Use SWR to fetch and cache itinerary data
  const { data: itineraries, mutate } = useSWR(
    user?.googleId ? ['/api/users/itineraries', user.googleId] : null,
    ([url, token]) => fetcher(url, token),
    {
      fallbackData: initialItineraries,
      refreshInterval: 5000 // Refresh every 5 seconds
    }
  );

  const handleDelete = async (id: string) => {
    if (!user?.googleId) return;

    setDeletingId(id);
    try {
      await axios.delete(`/api/users/itineraries/${id}`, {
        headers: { Authorization: `Bearer ${user.googleId}` }
      });
      onRefresh();
      setAlertMessage('Itinerary deleted successfully');
      setShowAlert(true);
    } catch (error) {
      console.error('Error deleting itinerary:', error);
      setAlertMessage('Failed to delete itinerary');
      setShowAlert(true);
    } finally {
      setDeletingId(null);
    }
  };

  const handleUpdateSchedule = async (itineraryId: string, days: any[]) => {
    if (!user?.googleId) return;

    try {
      const response = await axios.put(
        `/api/users/itineraries/${itineraryId}`,
        { days },
        { headers: { Authorization: `Bearer ${user.googleId}` } }
      );

      // Update the local state with the server response
      await mutate(currentData => {
        if (!currentData) return currentData;
        
        return currentData.map((itinerary: any) => {
          if (itinerary._id === itineraryId) {
            return {
              ...itinerary,
              days: response.data.days || days // Use server response if available, fallback to local days
            };
          }
          return itinerary;
        });
      }, false); // Set false to avoid revalidation

      setAlertMessage('Schedule updated successfully');
      setShowAlert(true);
    } catch (error) {
      console.error('Error updating schedule:', error);
      setAlertMessage('Failed to update schedule');
      setShowAlert(true);
    }
  };

  const handleDragStart = (event: any) => {
    document.body.style.overflow = 'hidden';
    const scheduleContainer = document.querySelector('.schedule-container') as HTMLElement;
    if (scheduleContainer) {
      scheduleContainer.style.overflow = 'auto';
    }
    
    setActiveId(event.active.id);
    setActiveDragData(event.active.data.current);
  };

  const handleDragEnd = (event: any) => {
    document.body.style.overflow = 'auto';
    const scheduleContainer = document.querySelector('.schedule-container') as HTMLElement;
    if (scheduleContainer) {
      scheduleContainer.style.overflow = 'auto';
    }

    const { active, over } = event;
    
    if (over && active.id !== over.id) {
      const dropData = over.data.current as DroppableData;
      if (dropData?.date) {
        // Handle dropping onto schedule
        const itinerary = itineraries.find(i => expandedId === i._id);
        if (itinerary) {
          const days = [...(itinerary.destination.days || [])];
          const dayIndex = days.findIndex(d => d.date === dropData.date);
          
          const newItem = {
            type: activeDragData?.type,
            title: activeDragData?.title,
            description: activeDragData?.description,
            location: activeDragData?.location
          };

          if (dayIndex >= 0) {
            days[dayIndex].schedule = [...days[dayIndex].schedule, newItem];
          } else {
            days.push({
              date: dropData.date,
              schedule: [newItem]
            });
          }

          handleUpdateSchedule(itinerary._id, days);
        }
      }
    }

    setActiveId(null);
    setActiveDragData(null);
  };

  const formatDate = (dateString: string) => {
    try {
      const date = new Date(dateString);
      if (!isValid(date)) {
        return 'Invalid date';
      }
      return format(date, 'MMM d, yyyy');
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'Invalid date';
    }
  };

  const handleSetDates = async (itineraryId: string, startDate: Date | null, endDate: Date | null, numberOfDays?: number) => {
    if (!user?.googleId) return;

    try {
      const updateData = startDate && endDate 
        ? { 
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString()
          }
        : { 
            startDate: null,
            endDate: null,
            numberOfDays
          };

      const response = await axios.put(
        `/api/users/itineraries/${itineraryId}`,
        updateData,
        { headers: { Authorization: `Bearer ${user.googleId}` } }
      );

      // Immediately update the local data with the server response
      mutate(
        currentData => ({
          ...currentData,
          itineraries: currentData.itineraries.map(itinerary =>
            itinerary._id === itineraryId ? response.data : itinerary
          )
        }),
        false // Set to false to avoid revalidation
      );

      // Then trigger a full revalidation to ensure we're in sync
      await mutate();
      
      setShowDateModal(false);
      setSelectedItineraryId(null);
      setAlertMessage('Trip dates updated successfully');
      setShowAlert(true);
    } catch (error) {
      console.error('Error updating trip dates:', error);
      setAlertMessage('Failed to update trip dates');
      setShowAlert(true);
    }
  };

  if (isLoading) {
    return (
      <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
        {[1, 2, 3].map((i) => (
          <div key={i} className="animate-pulse bg-white/5 rounded-lg h-48" />
        ))}
      </div>
    );
  }

  if (itineraries.length === 0) {
    return (
      <div className="text-center py-12">
        <p className="text-gray-400">No itineraries yet. Create one to get started!</p>
      </div>
    );
  }

  return (
    <DndContext
      sensors={sensors}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      measuring={{
        droppable: {
          strategy: MeasuringStrategy.Always
        }
      }}
      modifiers={[restrictToWindowEdges]}
    >
      <div className="space-y-6">
        {showAlert && (
          <Alert
            type={alertMessage.includes('success') ? 'success' : 'error'}
            message={alertMessage}
            onClose={() => setShowAlert(false)}
          />
        )}

        {itineraries.map((itinerary) => (
          <div
            key={`itinerary-${itinerary._id}`}
            className="group bg-gray-900/60 backdrop-blur-sm rounded-xl overflow-hidden border border-white/10 hover:border-accent-500/30 hover:bg-gray-900/80 transition-all duration-300 shadow-lg hover:shadow-accent-500/10 hover:-translate-y-0.5"
          >
            <div className="p-4 sm:p-6">
              <ItineraryHeader
                title={itinerary.title}
                destination={itinerary.destination.name}
                startDate={itinerary.destination.startDate}
                endDate={itinerary.destination.endDate}
                numberOfDays={itinerary.destination.numberOfDays}
                daysCount={itinerary.destination.days?.length || 0}
                bestTimeToVisit={itinerary.destination.bestTimeToVisit}
                isExpanded={expandedId === itinerary._id}
                isDeleting={deletingId === itinerary._id}
                onDelete={() => handleDelete(itinerary._id)}
                onToggleExpand={() => setExpandedId(expandedId === itinerary._id ? null : itinerary._id)}
                onSetDates={() => {
                  setSelectedItineraryId(itinerary._id);
                  setShowDateModal(true);
                }}
              />

              {expandedId === itinerary._id && (
                <div className="mt-6 border-t border-white/10 pt-6 space-y-4">
                  {itinerary.description && (
                    <div className="bg-white/5 rounded-lg p-4">
                      <p className="text-gray-300">{itinerary.description}</p>
                    </div>
                  )}

                  <ItineraryDetails 
                    destination={itinerary.destination}
                    itineraryId={itinerary._id}
                    onActivitySaved={() => mutate()}
                    onDiningSaved={() => mutate()}
                    numberOfDays={itinerary.destination.numberOfDays}
                    daysCount={itinerary.destination.days?.length || 0}
                  />

                  {/* Draggable Activities */}
                  {itinerary.destination.savedActivitySuggestions?.length > 0 && (
                    <div className="space-y-2">
                      <div className="flex items-center gap-2">
                        <h4 className="text-lg font-medium text-white flex items-center gap-2">
                          <button 
                            onClick={() => setShowActivities(!showActivities)}
                            className="p-1.5 bg-accent-500/10 text-accent-400 hover:text-gray-400 rounded-lg transition-all duration-200"
                          >
                            {showActivities ? (
                              <ChevronUp className="h-4 w-4 stroke-[2.5]" />
                            ) : (
                              <ChevronDown className="h-4 w-4 stroke-[2.5]" />
                            )}
                          </button>
                          Activities
                        </h4>
                      </div>
                      {showActivities && (
                        <div className="overflow-x-auto bg-emerald-500/5 rounded-lg p-4">
                          <div className="flex gap-3 pb-4 transition-transform duration-300 ease-in-out md:flex-row">
                            {itinerary.destination.savedActivitySuggestions?.map((activity) => (
                              <div className="w-full md:w-80 flex-shrink-0">
                                <DraggableItem
                                  key={activity._id}
                                  id={activity._id}
                                  type="activity"
                                  title={activity.title}
                                  description={activity.description}
                                  duration={activity.duration}
                                  location={activity.location}
                                  price={activity.price}
                                  links={activity.links}
                                />
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  )}

                  {/* Draggable Dining */}
                  {itinerary.destination.savedDining?.length > 0 && (
                    <div className="space-y-2">
                      <div className="flex items-center justify-between">
                        <h4 className="text-lg font-medium text-white flex items-center gap-2">
                          <button 
                            onClick={() => setShowDining(!showDining)}
                            className="p-1.5 bg-accent-500/10 text-accent-400 hover:text-gray-400 rounded-lg transition-all duration-200"
                          >
                            {showDining ? (
                              <ChevronUp className="h-4 w-4 stroke-[2.5]" />
                            ) : (
                              <ChevronDown className="h-4 w-4 stroke-[2.5]" />
                            )}
                          </button>
                          Dining
                        </h4>
                      </div>
                      {showDining && (
                        <div className="overflow-x-auto bg-orange-500/5 rounded-lg p-4">
                          <div className="flex gap-3 pb-4 transition-transform duration-300 ease-in-out md:flex-row">
                            {itinerary.destination.savedDining.map((dining) => (
                              <div className="w-full md:w-80 flex-shrink-0">
                                <DraggableItem
                                  key={dining._id}
                                  id={dining._id}
                                  type="dining"
                                  title={dining.name}
                                  description={dining.description}
                                  location={dining.location}
                                  rating={dining.rating}
                                  links={dining.links}
                                  specialties={dining.specialties}
                                />
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  )}

                  {/* Schedule */}
                  <div className="space-y-2">
                    <h4 className="text-lg font-medium text-white">Schedule</h4>
                    <ScheduleView
                      startDate={itinerary.destination.startDate}
                      endDate={itinerary.destination.endDate}
                      numberOfDays={itinerary.destination.numberOfDays}
                      days={itinerary.destination.days || []}
                      onUpdateSchedule={(days) => handleUpdateSchedule(itinerary._id, days)}
                      onSetDates={() => {
                        setSelectedItineraryId(itinerary._id);
                        setShowDateModal(true);
                      }}
                    />
                  </div>

                  <LocalTips tips={itinerary.destination.localTips} />

                  <div className="text-sm text-gray-400">
                    Created on {formatDate(itinerary.createdAt)}
                  </div>
                </div>
              )}
            </div>
          </div>
        ))}

        {/* Date Setting Modal */}
        {showDateModal && selectedItineraryId && (
          <DateSettingModal
            isOpen={showDateModal}
            onClose={() => {
              setShowDateModal(false);
              setSelectedItineraryId(null);
            }}
            onSave={(startDate, endDate, numberOfDays) => 
              handleSetDates(selectedItineraryId, startDate, endDate, numberOfDays)
            }
            currentItinerary={itineraries.find(i => i._id === selectedItineraryId)}
          />
        )}

        {/* Drag Overlay */}
        <DragOverlay dropAnimation={null}>
          {activeId && activeDragData && (
            <DragOverlayItem {...activeDragData} />
          )}
        </DragOverlay>
      </div>
    </DndContext>
  );
}