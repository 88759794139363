import { useState } from 'react';
import { RefreshCw, Plus, BookmarkPlus, Share2, MapPin, Info } from 'lucide-react';
import Button from '../ui/Button';
import Alert from '../ui/Alert';
import DestinationCard from './DestinationCard';
import ShareModal from './ShareModal';
import type { TravelRecommendation } from '../../services/openai';
import type { SurveyResponse } from '../../types/survey';
import { generateTravelRecommendations, generateTripDescription } from '../../services/openai';
import { useTripSlip } from '../../contexts/TripSlipContext';
import { useAuth } from '../../contexts/AuthContext';
import axios from 'axios';
import AuthBlur from './AuthBlur';
interface TravelRecommendationsProps {
  recommendation: TravelRecommendation;
  onStartOver: () => void;
  surveyResponses: SurveyResponse;
}

export default function TravelRecommendations({
  recommendation,
  onStartOver,
  surveyResponses,
}: TravelRecommendationsProps) {
  const [selectedDestination, setSelectedDestination] = useState(0);
  const [currentRecommendation, setCurrentRecommendation] = useState(recommendation);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isSaving, setIsSaving] = useState<number | null>(null);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [alert, setAlert] = useState<{
    show: boolean;
    type: 'success' | 'error';
    message: string;
  }>({
    show: false,
    type: 'success',
    message: ''
  });
  const { addDestination } = useTripSlip();
  const { isAuthenticated, user } = useAuth();
  const [activeTooltip, setActiveTooltip] = useState<{ index: number, type: 'add' | 'save' } | null>(null);

  const loadMoreDestinations = async () => {
    setIsLoadingMore(true);
    try {
      const excludeDestinations = currentRecommendation.destinations.map(d => d.name);
      const newRecommendation = await generateTravelRecommendations(surveyResponses, excludeDestinations);
      setCurrentRecommendation(newRecommendation);
      setSelectedDestination(0);
    } catch (error) {
      console.error('Error loading more destinations:', error);
    } finally {
      setIsLoadingMore(false);
    }
  };

  const handleSaveDestination = async (index: number) => {
    if (!isAuthenticated || !user?.googleId) {
      return;
    }

    setIsSaving(index);
    try {
      const destination = currentRecommendation.destinations[index];
      
      // Generate trip description before saving
      const tripDescription = await generateTripDescription(
        destination.name,
        surveyResponses
      );

      await axios.post('/api/users/destinations', {
        destination: {
          name: destination.name,
          description: destination.description,
          activities: destination.activities,
          estimatedBudget: destination.estimatedBudget,
          bestTimeToVisit: destination.bestTimeToVisit,
          accommodation: destination.accommodation,
          recommendedDuration: destination.recommendedDuration,
          localTips: destination.localTips,
          tripDescription
        },
        surveyResponses
      }, {
        headers: { Authorization: `Bearer ${user.googleId}` }
      });

      setAlert({
        show: true,
        type: 'success',
        message: 'Destination saved successfully!'
      });
    } catch (error) {
      console.error('Error saving destination:', error);
      setAlert({
        show: true,
        type: 'error',
        message: 'Failed to save destination. Please try again.'
      });
    } finally {
      setIsSaving(null);
    }
  };

  const handleAddToTripSlip = (index: number) => {
    const destination = currentRecommendation.destinations[index];
    addDestination(destination, surveyResponses);
    setAlert({
      show: true,
      type: 'success',
      message: 'Added to Trip Slip successfully!'
    });
  };

  const handleTooltipToggle = (index: number, type: 'add' | 'save') => {
    if (activeTooltip?.index === index && activeTooltip?.type === type) {
      setActiveTooltip(null);
    } else {
      setActiveTooltip({ index, type });
    }
  };

  return (
    <div className="max-w-4xl mx-auto space-y-8">
      {alert.show && (
        <Alert
          type={alert.type}
          message={alert.message}
          onClose={() => setAlert(prev => ({ ...prev, show: false }))}
        />
      )}

      <div className="text-center space-y-4">
        <div className="flex items-center justify-center gap-4">
          <h2 className="text-3xl font-bold text-white">Your Perfect Destinations</h2>
          <button
            onClick={() => setIsShareModalOpen(true)}
            className="flex items-center gap-2 px-4 py-2 bg-accent-500/20 hover:bg-accent-500/30 text-accent-300 rounded-lg transition-all duration-200"
          >
            <Share2 className="h-5 w-5" />
            <span>Share Destinations</span>
          </button>
        </div>
        <p className="text-xl text-gray-300">{currentRecommendation.travelStyle}</p>
      </div>
      <AuthBlur isAuthenticated={isAuthenticated}>
      <div className="flex flex-wrap gap-4 justify-center">
        {currentRecommendation.destinations.map((dest, index) => (
          <div key={dest.name} className="relative">
            <div className="relative inline-block group">
              <button
                onClick={() => setSelectedDestination(index)}
                className={`
                  relative px-6 py-3 rounded-xl transition-all duration-300
                  ${selectedDestination === index
                    ? 'bg-accent-500 text-white scale-105'
                    : 'bg-white/5 text-gray-300 hover:bg-white/10 hover:scale-105'
                  }
                `}
              >
                <span className="flex items-center gap-2">
                  {dest.name}
                  <div className="flex items-center gap-1">
                    <div className="relative">
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleAddToTripSlip(index);
                        }}
                        onTouchStart={(e) => {
                          e.stopPropagation();
                          handleTooltipToggle(index, 'add');
                        }}
                        onMouseEnter={() => handleTooltipToggle(index, 'add')}
                        onMouseLeave={() => setActiveTooltip(null)}
                        className={`
                          p-1 rounded-full 
                          transition-all duration-300
                          ${selectedDestination === index
                            ? 'bg-white text-accent-500 hover:bg-gray-100'
                            : 'bg-accent-500 text-white hover:bg-accent-600'
                          }
                        `}
                      >
                        <Plus className="h-3 w-3" />
                      </button>
                      {activeTooltip?.index === index && activeTooltip?.type === 'add' && (
                        <div className="absolute left-1/2 -translate-x-1/2 bottom-full mb-2 z-50">
                          <div className="bg-gray-900 text-white text-xs px-2 py-1 rounded whitespace-nowrap">
                            Add to Trip Slip
                            <div className="absolute left-1/2 -translate-x-1/2 top-full w-0 h-0 border-x-4 border-x-transparent border-t-4 border-t-gray-900"></div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="relative">
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSaveDestination(index);
                        }}
                        onTouchStart={(e) => {
                          e.stopPropagation();
                          handleTooltipToggle(index, 'save');
                        }}
                        onMouseEnter={() => handleTooltipToggle(index, 'save')}
                        onMouseLeave={() => setActiveTooltip(null)}
                        disabled={!isAuthenticated || isSaving === index}
                        className={`
                          p-1 rounded-full 
                          transition-all duration-300
                          ${!isAuthenticated 
                            ? 'bg-gray-500 text-gray-300 cursor-not-allowed'
                            : selectedDestination === index
                              ? 'bg-white text-accent-500 hover:bg-gray-100'
                              : 'bg-accent-500 text-white hover:bg-accent-600'
                          }
                        `}
                      >
                        <BookmarkPlus className={`h-3 w-3 ${isSaving === index ? 'animate-pulse' : ''}`} />
                      </button>
                      {activeTooltip?.index === index && activeTooltip?.type === 'save' && (
                        <div className="absolute left-1/2 -translate-x-1/2 bottom-full mb-2 z-50">
                          <div className="bg-gray-900 text-white text-xs px-2 py-1 rounded whitespace-nowrap">
                            {!isAuthenticated 
                              ? 'Sign in to save destination'
                              : 'Save Destination'
                            }
                            <div className="absolute left-1/2 -translate-x-1/2 top-full w-0 h-0 border-x-4 border-x-transparent border-t-4 border-t-gray-900"></div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </span>
              </button>
            </div>
          </div>
        ))}
      </div>

      <DestinationCard 
        destination={currentRecommendation.destinations[selectedDestination]}
        onSave={() => handleSaveDestination(selectedDestination)}
        isSaving={isSaving === selectedDestination}
        isAuthenticated={isAuthenticated}
        onAddToTripSlip={() => handleAddToTripSlip(selectedDestination)}
      />
      <div className="flex flex-col sm:flex-row justify-between items-center gap-4 pt-4">
        <Button
          onClick={onStartOver}
          variant="secondary"
          className="w-full sm:w-auto text-gray-300 hover:text-white bg-white/10"
        >
          Start Over
        </Button>
        <div className="flex flex-col sm:flex-row items-center gap-4 w-full sm:w-auto">
          <Button
            onClick={() => handleAddToTripSlip(selectedDestination)}
            className="w-full sm:w-auto bg-accent-500 hover:bg-accent-600 text-white"
          >
            <MapPin className="h-4 w-4 mr-2" />
            Plan Trip
          </Button>
          <Button
            onClick={loadMoreDestinations}
            className="w-full sm:w-auto bg-gradient-to-r from-primary-700 to-accent-600 text-white"
            disabled={isLoadingMore}
          >
            <RefreshCw className={`h-4 w-4 mr-2 ${isLoadingMore ? 'animate-spin' : ''}`} />
            More Options
          </Button>
        </div>
      </div>
      </AuthBlur>
      <ShareModal
        isOpen={isShareModalOpen}
        onClose={() => setIsShareModalOpen(false)}
        recommendation={currentRecommendation}
        surveyResponses={surveyResponses}
      />
    </div>
  );
}