import { format } from 'date-fns';
import { useDroppable } from '@dnd-kit/core';

interface DateCardProps {
  date: string;
  isSelected: boolean;
  hasItems: boolean;
  onClick: () => void;
  itemCount: number;
  getScheduleItems: (date: string) => Array<{ type: string }>;
}

export default function DateCard({
  date,
  isSelected,
  hasItems,
  onClick,
  getScheduleItems
}: DateCardProps) {
  const { setNodeRef, isOver } = useDroppable({
    id: `schedule-${date}`,
    data: { date }
  });

  const isNumberedDay = date.startsWith('day-');
  const dayNumber = isNumberedDay ? parseInt(date.split('-')[1]) : null;

  // Count activities and meals
  const items = getScheduleItems(date);
  const activityCount = items.filter(item => item.type === 'activity').length;
  const mealCount = items.filter(item => item.type === 'dining').length;

  return (
    <div
      ref={setNodeRef}
      onClick={onClick}
      className={`
        relative flex-shrink-0 w-48 h-36 rounded-xl transition-all duration-200 cursor-pointer mt-1 p-0.5
        ${isSelected
          ? 'ring-2 ring-accent-500 transform scale-100'
          : isOver
          ? 'ring-2 ring-accent-400/50'
          : ''
        }
      `}
    >
      {/* Background with gradient */}
      <div className={`
        absolute inset-1 rounded-xl transition-all duration-200
        ${isSelected
          ? 'bg-gradient-to-br from-accent-500 to-primary-600 scale-100'
          : hasItems
          ? 'bg-gradient-to-br from-accent-500/20 to-primary-600/20'
          : 'bg-gradient-to-br from-gray-800/50 to-gray-900/50'
        }
        ${isOver ? 'opacity-70' : 'opacity-100'}
      `} />

      {/* Content */}
      <div className="relative p-4 h-full flex flex-col justify-between">
        {/* Date Info */}
        <div>
          <div className={`
            text-sm font-medium mb-1 transition-colors
            ${isSelected ? 'text-white/80' : 'text-gray-400'}
          `}>
            {isNumberedDay ? 'Day' : format(new Date(date), 'EEEE')}
          </div>

          <div className="flex items-baseline gap-2">
            <span className={`
              text-4xl font-bold transition-colors
              ${isSelected ? 'text-white' : hasItems ? 'text-accent-400' : 'text-gray-300'}
            `}>
              {isNumberedDay ? dayNumber : format(new Date(date), 'd')}
            </span>
            {!isNumberedDay && (
              <span className={`
                text-sm transition-colors
                ${isSelected ? 'text-white/80' : 'text-gray-400'}
              `}>
                {format(new Date(date), 'MMM')}
              </span>
            )}
          </div>
        </div>

        {/* Item Count */}
        {hasItems && (
          <div className="flex items-center justify-between">
            <div className={`
              text-sm space-y-0.5
              ${isSelected ? 'text-white/80' : 'text-gray-400'}
            `}>
              {activityCount > 0 && (
                <div>{activityCount} {activityCount === 1 ? 'Activity' : 'Activities'}</div>
              )}
              {mealCount > 0 && (
                <div>{mealCount} {mealCount === 1 ? 'Meal' : 'Meals'}</div>
              )}
            </div>
            <div className={`
              flex items-center justify-center w-8 h-8 rounded-full text-sm font-medium
              ${isSelected 
                ? 'bg-white text-accent-500' 
                : 'bg-accent-500/20 text-accent-400'
              }
            `}>
              {activityCount + mealCount}
            </div>
          </div>
        )}

        {/* Drop Indicator */}
        {isOver && !isSelected && (
          <div className="absolute inset-0 border-2 border-accent-400/50 rounded-xl animate-pulse" />
        )}
      </div>
    </div>
  );
}