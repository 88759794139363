import { Calendar, MapPin, Clock, ChevronDown, ChevronUp, Trash2, PencilIcon, Sun } from 'lucide-react';
import { format, isValid } from 'date-fns';

interface ItineraryHeaderProps {
  title: string;
  destination: string;
  startDate: string | null;
  endDate: string | null;
  numberOfDays?: number | null;
  daysCount: number;
  bestTimeToVisit?: string;
  isExpanded: boolean;
  isDeleting: boolean;
  onDelete: () => void;
  onToggleExpand: () => void;
  onSetDates?: () => void;
}

export default function ItineraryHeader({
  title,
  destination,
  startDate,
  endDate,
  numberOfDays,
  daysCount,
  bestTimeToVisit,
  isExpanded,
  isDeleting,
  onDelete,
  onToggleExpand,
  onSetDates
}: ItineraryHeaderProps) {
  const getDateRange = (start: string | null, end: string | null) => {
    if (!start || !end) return null;
    
    try {
      const startDate = new Date(start);
      const endDate = new Date(end);
      
      if (!isValid(startDate) || !isValid(endDate)) {
        return null;
      }

      return `${format(startDate, 'MMM d')} - ${format(endDate, 'MMM d, yyyy')}`;
    } catch (error) {
      console.error('Error formatting date range:', error);
      return null;
    }
  };

  const dateRange = getDateRange(startDate, endDate);
  
  // Calculate display days based on the specific itinerary
  const displayDays = numberOfDays ?? daysCount;

  console.log('Header values:', { title, numberOfDays, daysCount, displayDays }); // Debug log

  return (
    <div className={`bg-white/5 rounded-lg border ${isExpanded ? 'border-accent-400/20' : 'border-white/10'} p-4 transition-colors duration-200`}>
      <div className="flex items-center justify-between gap-4">
        <div className="min-w-0 flex-1">
          <h3 className="text-xl font-bold text-white mb-2 truncate">{title}</h3>
          <div className="flex flex-wrap items-center gap-x-4 gap-y-2 text-sm text-gray-300">
            <div className="flex items-center gap-2">
              <MapPin className="h-4 w-4 text-accent-400" />
              <span>{destination}</span>
            </div>
            <button
              onClick={onSetDates}
              className="flex items-center gap-2 hover:text-white transition-colors group"
            >
              <Calendar className="h-4 w-4 text-accent-400 group-hover:text-accent-300" />
              {dateRange ? (
                <span className="flex items-center gap-1">
                  {dateRange}
                  <PencilIcon className="h-3 w-3 opacity-0 group-hover:opacity-100 transition-opacity" />
                </span>
              ) : (
                <span className="text-accent-400 hover:text-accent-300 underline">
                  Set trip dates
                </span>
              )}
            </button>
            <div className="flex items-center gap-2">
              <Clock className="h-4 w-4 text-accent-400" />
              <span>{displayDays} days</span>
            </div>
            {bestTimeToVisit && (
              <div className="flex items-center gap-2">
                <Sun className="h-4 w-4 text-accent-400" />
                <span>{bestTimeToVisit}</span>
              </div>
            )}
          </div>
        </div>

        <div className="flex items-center gap-2 flex-shrink-0">
          <button
            onClick={onDelete}
            disabled={isDeleting}
            className="p-2 text-gray-400 hover:text-red-400 hover:bg-red-500/10 rounded-lg transition-colors"
            aria-label="Delete itinerary"
          >
            <Trash2 className={`h-5 w-5 ${isDeleting ? 'animate-spin' : ''}`} />
          </button>
          <button
            onClick={onToggleExpand}
            className="p-2 text-gray-400 hover:text-white hover:bg-white/10 rounded-lg transition-colors"
            aria-label={isExpanded ? "Collapse itinerary" : "Expand itinerary"}
          >
            {isExpanded ? (
              <ChevronUp className="h-5 w-5" />
            ) : (
              <ChevronDown className="h-5 w-5" />
            )}
          </button>
        </div>
      </div>
    </div>
  );
}